<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/productProperties" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>

        <span v-if="$route.name === 'addProductProperty'">
          Категори нэмэх
          <div class="editField">
            <el-row :gutter="16">
              <el-col :span="16" :offset="4">
                <div class="panel">
                  <el-form
                    :model="property"
                    :rules="rules"
                    ref="property"
                    label-width="220px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="Нэр" prop="name">
                      <el-input
                        style="width:250px"
                        size="mini"
                        v-model="property.name"
                        placeholder="Шинж чанарын нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Категори" prop="service">
                      <el-select
                        placeholder="Категори"
                        v-model="property.service"
                      >
                        <el-option
                          v-for="service in servicesList"
                          :label="service.name_mon"
                          :value="service.name_eng"
                          :key="service.name_eng"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-button
                      type="primary"
                      style="margin-right: 20;"
                      @click="save"
                      >Нэмэх</el-button
                    >
                    <router-link to="/productProperties" class="back-button">
                      <el-button>
                        Буцах
                      </el-button>
                    </router-link>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  created() {
    this.getServices();
    if (this.$route.name === "addProductProperty") {
      this.$notify({
        title: "Бүтээгдэхүүний шинж чанарын мэдээлэл нэмэх хуудас",
        message: "Мэдээллээ бүрэн гүйцэт оруулна уу",
        type: "info"
      });
    }
  },
  data() {
    return {
      servicesList: [],
      loadUpload: false,
      property: {
        name: "",
        value: "",
        service: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "Та шинж чанарын нэрээ оруулна уу",
            trigger: "blur"
          }
        ],
        service: [
          {
            required: true,
            message: "Та категори - оо сонгоно уу",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    save() {
      this.$refs.property.validate(valid => {
        if (valid) {
          if (this.$route.name === "addProductProperty") {
            service.addProductProperty(this.property).then(response => {
              if (response.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Бүтээгдэхүүний шинж чанар нэмэгдлээ",
                  type: "success"
                });
                this.$router.go(-1);
              } else {
                this.$notify({
                  title: "Амжилтгүй",
                  message: response.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getServices() {
      service.getServices(false, "active", true).then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
</style>
